<template>
  <div>
    <div class="box" v-html="dataInfo.content">
<!--      <div v-html="info"></div>-->
<!--      <img :src="dataInfo.image" width="100%" alt="">-->
<!--      <div>
        <el-table
            :data="tableData"
            border
            style="width: 90%;margin: 0 auto"
            header-cell-style="background:#007B8A;color:#fff">
          <el-table-column
              prop="route"
              :label="tType == 'CH'?'路线':'path'"
              align="center">
          </el-table-column>
          <el-table-column
              prop="start"
              :label="tType == 'CH'?'起始站点':'The initial site'"
              align="center">
            <template slot-scope="scope">
              <div v-for="(item,index) of scope.row.start" :key="index">
                {{item}}
              </div>
            </template>
          </el-table-column>
          <el-table-column
              prop="scenic"
              align="center"
              :label="tType == 'CH'?'景区站点':'The scenic site'">
            <template slot-scope="scope">
              <div v-for="(item,index) of scope.row.scenic" :key="index">
                {{item}}
              </div>
            </template>
          </el-table-column>
          <el-table-column
              prop="fare"
              align="center"
              :label="tType == 'CH'?'票价':'fares'">
          </el-table-column>
          <el-table-column
              prop="operation"
              align="center"
              :label="tType == 'CH'?'运营时间':'service time'">
            <template slot-scope="scope">
              <div v-for="(item,index) of scope.row.operation" :key="index">
                {{item}}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="guize">
        <div>特殊线路:阳山赏花专线南禅寺—阳山大道（牌坊)，直达中间无停靠站</div>
        <div>营运时间:为期&#45;&#45;周视桃花节而定，南禅寺发车时间为7:30—10:00，阳山发车时间为</div>
        <div>11:00——16:00</div>
        <div>票价:5元/人次，仅限投币或移动支付</div>
      </div>-->
    </div>
  </div>
</template>

<script>
import AllAddress from "@/views/vacation/AddressData";
import {getBannerowInfo} from "@/api/table";

export default {
  name: "gongjiao",
  props:['info'],
  data(){
    return {
      tType:'CH',
      tableData: [
        {
          id:1,
          route: '地铁一号线',
          start: ["堰桥-长广溪","长广溪-堰桥"],
          scenic: ["南禅寺站"],
          fare: '2-6元',
          operation: ["6:00-22:37","5:59-22:29"],
        },
        {
          id:2,
          route: '105路',
          start: ["八佰伴(学前东路)-新城分公司"],
          scenic: ["二院(中信银行)","南禅寺(朝阳广场)","跨塘桥(南长街)"],
          fare: '2元',
          operation: ["首车:06:28","末车21:58"],
        },
        {
          id:3,
          route: '113路',
          start: ["招商城汽车南站-华庄街道"],
          scenic: ["南长街（永乐路）","妙光桥"],
          fare: '2元',
          operation: ["首车：06:20","末车：19:10"],
        },
        {
          id:4,
          route: '118路',
          start: ["火车站-运河西路公交停车场"],
          scenic: ["二院（中信银行）","朝阳广场（南禅寺）"],
          fare: '2元',
          operation: ["首车：06:18","末车：21:34"],
        },{
          id:5,
          route: '118路区间',
          start: ["南禅寺（朝阳广场)-运河西路公交停车场"],
          scenic: ["二院（中信银行）","朝阳广场（南禅寺）","南禅寺（朝阳广场）"],
          fare: '2元',
          operation: ["首车：08:50","末车：18:19"],
        },{
          id:6,
          route: '12路',
          start: ["南禅寺（朝阳广场）站-旅游商贸学校站"],
          scenic: ["二院（中信银行）","南禅寺（朝阳广场）"],
          fare: '2元',
          operation: ["首车：05:20","末车：19:00"],
        },{
          id:7,
          route: '12路支线',
          start: ["朝阳广场（南禅寺）-欧典家园"],
          scenic: ["二院（中信银行）","朝阳广场（南禅寺）","南禅寺（朝阳广场）"],
          fare: '2元',
          operation: ["首车：06:00","末车：18:00"],
        },{
          id:8,
          route: '135路',
          start: ["朝阳广场（南禅寺）-清晏路公交停车场"],
          scenic: ["二院（中信银行）","朝阳广场（南禅寺）"],
          fare: '2元',
          operation: ["首车：06:55","末车：18:55"],
        },{
          id:9,
          route: '15路',
          start: ["招商城汽车南站-盛岸公交停车场站"],
          scenic: ["南禅寺（朝阳广场）","跨塘桥（南长街)", "妙光桥"],
          fare: '2元',
          operation: ["首车：05:20","末车：21:00"],
        },{
          id:10,
          route: '19路',
          start: ["火车站-公交梅村总站"],
          scenic: ["南禅寺（朝阳广场）", "妙光桥"],
          fare: '2元',
          operation: ["首车：06:00","末车：20:50"],
        },{
          id:11,
          route: '201路',
          start: ["新光嘉园-民丰公寓"],
          scenic: ["二院（中信银行）", "朝阳广场（南禅寺）","永丰路（通扬路）"],
          fare: '2元',
          operation: ["首车：06:00","末车：18:30"],
        },{
          id:12,
          route: '23路',
          start: ["运河西路公交停车场站-无锡中央车站"],
          scenic: ["南禅寺（朝阳广场）"],
          fare: '2元',
          operation: ["首车：05:40","末车：20:15"],
        },{
          id:13,
          route: '23路区间',
          start: ["运河西路公交停车场站-南禅寺（朝阳广场）"],
          scenic: ["二院（中信银行)","南禅寺（朝阳广场）"],
          fare: '2元',
          operation: ["首车：08:42","末车：17:00"],
        },
      ],
      dataInfo:{},
    }
  },
  created() {
    this.tType = sessionStorage.getItem('textType')
  },
  mounted() {
    // this.dataInfo = this.info
    this.createList()
  },
  methods:{
    createList(){
      getBannerowInfo({
        bannerType:'5',   // 必填 banner类型：1度假区概况 2度假区总览图 3交通指引 4官网首页轮播图 5交通指引（公交、高铁、飞机）6首页度假区概况
        // sightseeingOnline:'1',   // 是否上线 0否 1是
      }).then(res=>{
        console.log(res)
        if (res.code==200) {
          this.dataInfo = res.rows[0]
          // res.rows.forEach((item,index)=>{
          //   if(item.content=='自驾'){this.ZJInfo = item}
          //   if(item.content=='公交'){this.GJInfo = item}
          //   if(item.content=='铁路'){this.TLInfo = item}
          //   if(item.content=='飞机'){this.FJInfo = item}
          // })
          // this.totalCount=res.total
        }
      })
    },
  }
}
</script>

<style scoped lang="less">
.box{
  width: 100%;
  min-height: 300px;
  background: #FFFFFF;
  margin-bottom: 120px;
  border-radius: 0 0 20px 20px;
  display: block;
  overflow: hidden;
  img{
    max-width: 100%;
  }
}
.guize{
  width: 90%;margin: 0 auto;
  padding: 20px;
  font-family: 宋体;
  color: #606266;
}
</style>
